import * as React from "react"
import Layout from "../components/layout"
import Logistics from "../components/Industries/Logistics"






const logistics = () => {
  return (
  <Layout>
   <Logistics />
  </Layout>
)
};
export default logistics;
