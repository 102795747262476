import React from "react"
import industryimage from "../../assets/images/banners/industry-images/logistics.png"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"
const Logistics = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="about-banner-wrap banner-space bg-img logistics">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Logistics & Transportation</h1>
                  <h5 className="font-weight--normal text-white">
                    For more than a decade, we've been at the forefront of
                    offering business solutions in Logistics and Transportation
                    sector.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">
                    Our Industry Solution
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Logisitics & <span className="text-color-primary">Transportation Solutions</span></h2>
                  Our AI and deep learning-based solutions are built to
                  help OEMs and AI automotive services enhance
                  productivity and make data-driven timely decisions. AI
                  has the ability to alter critical operations like
                  research and development, maintenance, and safety
                  regulation. In the meantime, this saves time and money
                  for automakers, allowing them to focus on operations
                  that have the most potential for growth in other
                  important functional departments.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={industryimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->
            <!--=========== About Company Area End ==========--> */}
      </div>
    </div>
  )
}
export default Logistics
